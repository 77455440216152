import get from 'lodash/get';
import logger from 'routes/middleware/logging/logger';
import {
  FETCH_PDP_LOT_SUCCESS,
  FETCH_PDP_LOT_ERROR,
  FETCH_INITIAL_LOT_DATA_SUCCESS,
  FETCH_INITIAL_LOT_DATA_ERROR,
  FETCH_PDP_BIDDER_STATUS_SUCCESS,
  FETCH_PDP_BIDDER_STATUS_ERROR,
  FETCH_PDP_LOT_LIVE_INFO_SUCCESS,
  FETCH_PDP_LOT_LIVE_INFO_ERROR,
  POST_PDP_ADD_TO_WATCH_LIST_SUCCESS,
  POST_PDP_ADD_TO_WATCH_LIST_ERROR,
  POST_PDP_REMOVE_FROM_WATCH_LIST_SUCCESS,
  POST_PDP_REMOVE_FROM_WATCH_LIST_ERROR,
  FETCH_PDP_SELLER_INFO_SUCCESS,
  FETCH_PDP_SELLER_INFO_ERROR,
  FETCH_PDP_WATCHER_COUNT_SUCCESS,
  FETCH_PDP_WATCHER_COUNT_ERROR,
  FETCH_PDP_USER_CONTACT_INFO_SUCCESS,
  FETCH_PDP_USER_CONTACT_INFO_ERROR,
  FETCH_PDP_STATES_SUCCESS,
  FETCH_PDP_STATES_ERROR,
  PDP_UPDATE_PLACE_BID_FORM_DATA,
  POST_PDP_FOLLOW_UN_FOLLOW_SELLER_SUCCESS,
  POST_PDP_FOLLOW_UN_FOLLOW_SELLER_ERROR,
  FETCH_PDP_IS_SELLER_FOLLOWED_SUCCESS,
  FETCH_PDP_IS_SELLER_FOLLOWED_ERROR,
  SET_PDP_ASYNC_STATUS,
  FETCH_PDP_REQUEST_FOR_APPROVAL_INIT_SUCCESS,
  FETCH_PDP_REQUEST_FOR_APPROVAL_INIT_ERROR,
  POST_PDP_UPDATE_BID_TOTAL_SUCCESS,
  POST_PDP_UPDATE_BID_TOTAL_ERROR,
  SET_PDP_SELECTED_BID_INCREMENT,
  SET_PDP_IS_LOADING,
  POST_PDP_NOTIFY_ME_SUCCESS,
  POST_PDP_NOTIFY_ME_ERROR,
  FETCH_PDP_REQUEST_FOR_APPROVAL_SUCCESS,
  FETCH_PDP_REQUEST_FOR_APPROVAL_ERROR,
  PDP_UPDATE_PLACE_BID_FORM_ERRORS,
  RESET_PDP_ERRORS,
  RESET_PDP_PLACE_BID_FORM_ERRORS,
  FETCH_PDP_SELLER_FOLLOW_COUNT_SUCCESS,
  FETCH_PDP_SELLER_FOLLOW_COUNT_ERROR,
  PDP_UPDATE_SELECTED_CARD_DETAILS,
  PDP_UPDATE_NEW_CARD_DETAILS,
  FETCH_PDP_BILLING_CARDS_ERROR,
  FETCH_PDP_BILLING_CARDS_SUCCESS,
  FETCH_PDP_SUBSCRIPTION_INFO_SUCCESS,
  FETCH_PDP_SUBSCRIPTION_INFO_ERROR,
  FETCH_PDP_ARTIST_INFO_SUCCESS,
  FETCH_PDP_ARTIST_INFO_ERROR,
  PDP_SET_BID_SUBMIT_RESPONSE,
  PDP_SET_BID_SUBMIT_ERROR_MESSAGE,
  PDP_SET_BID_SUBMIT_ERRORS_DATA,
  PDP_SET_SELECTED_BID_AMOUNT,
  PDP_SET_ARE_BILLING_SHIPPING_ADDRESS_SAME,
  PDP_USE_NEW_CREDIT_CARD,
  PDP_SET_ALGOLIA_PARAMS,
  PDP_SET_CURRENCY_COVERSION_RATE,
  PDP_SET_IS_PROD_ENV,
  PDP_FETCH_VIP_STATUS_SUCCESS,
  PDP_FETCH_VIP_STATUS_ERROR,
  PDP_SET_BID_CONCIERGE_STATUS,
  SET_PDP_CONCIERGE_MODAL_PROPS,
  SET_PDP_STATUS_CODE,
  SET_INV_PROMISE_MODAL_OPEN,
  SET_DYNAMIC_PRICING_MODAL_OPEN,
  FETCH_DYNAMIC_TERMS_SUCCESS,
} from '../../actions/constants';
import { PLACE_BID_NESTED_FORMS } from '../../utils/pdp/constants';
import { BID_SUBMIT_RESPONSE } from '../../components/pdp/pdp-modals/review-bid-modal/constants';

import { extractAuctionDateAndTimeZoneRegion, formatLotPhotosWithArtmyn } from './helpers';

const initialState = {
  isProd: false,
  lotData: undefined,
  catalogData: undefined,
  auctionHouseData: undefined,
  artistInfo: {
    fullName: '',
  },
  catalogRef: undefined,
  breadCrumbs: null,
  catalogTermsData: null,
  bidderStatus: null,
  bidData: undefined,
  algoliaParams: {},
  isFollowingSeller: false,
  sellerFollowCount: 0,
  lotLiveInfo: {},
  sellerInfo: undefined,
  contactInfo: {
    [PLACE_BID_NESTED_FORMS.SHIPPING_ADDRESS]: undefined,
    [PLACE_BID_NESTED_FORMS.BILLING_ADDRESS]: undefined,
    countries: [],
    [PLACE_BID_NESTED_FORMS.SMS_OPT_IN]: {
      smsOptIn: false,
      phone: '',
      countryCode: '',
    },
    formErrors: {
      [PLACE_BID_NESTED_FORMS.SMS_OPT_IN]: undefined,
      [PLACE_BID_NESTED_FORMS.SHIPPING_ADDRESS]: undefined,
    },
  },
  subscriptionInfo: {},
  asyncStatus: {},
  selectedBidIncrement: -1,
  selectedBidAmount: -1,
  approvalInfo: {},
  ccInfo: {
    showCreditCardSection: false,
    hasPaymentProcessing: false,
    userCreditCards: [],
    selectedCardDetails: {},
    newlyAddedCardDetails: {},
    isUseNewCard: false,
  },
  bidInfo: {
    errorMsg: '',
    dynamicPricing: {
      bpPostMinMax: 0,
      bpPreMinMax: 0,
      bpSavings: 0,
      bpSavingsPercentage: 0,
      totalPostMinMax: 0,
      totalPreMinMax: 0,
    },
  },
  isPromiseModalOpen: false,
  isDynamicPricingModalOpen: false,
  isLoading: false,
  bidSubmitResponse: BID_SUBMIT_RESPONSE.NONE,
  areBillingShippingAddressSame: true,
  isAlgoliaInitialized: false,
  isUserVip: false,
  conciergeStatus: false,
  conciergeModalProps: {
    showBackButton: false,
    showPrevModal: false
  },
  paymentAndTermsDetail: {
    paymentMethodsText: '',
    acceptedCreditCards: [],
    acceptedPaymentForms: '',
    shippingDetailsHTML: '',
    bidIncrements: [],
    buyersPremiums: [],
    conditionsOfSale: '',
    auctionTerms: [],
    isDataAvailable: false,
  },
  dynamicTerms: [],
  errors: {
    lotCallFailed: false,
    initialLotCallFailed: false,
    bidderStatusCallFailed: false,
    addToWatchListCallFailed: false,
    removeToWatchListCallFailed: false,
    watcherCountCallFailed: false,
    sellerInfoCallFailed: false,
    contactInfoCallFailed: false,
    fetchCountryStatesCallFailed: false,
    followUnFollowSellerCallFailed: false,
    isFollowedSellerCallFailed: false,
    requestForApprovalInitCallFailed: false,
    updateBidTotalCallFailed: false,
    notifyMecallFailed: false,
    requestForApprovalCallFailed: false,
    getArtistInfoCallFailed: false,
    sellerCountCallFailed: false,
    subscriptionInfoCallFailed: false,
  },
};

function pdp(state = initialState, action) {
  try {
    switch (action.type) {
      case FETCH_PDP_LOT_SUCCESS: {
        const {
          itemView: {
            lotId,
            artistName,
            artistFullName,
            artistFirstName,
            artistLastName,
            ref: lotRef,
            bidCount,
            estimateHigh,
            estimateLow,
            price: currentBid,
            currency,
            currencySymbol,
            priceResult: soldAmount,
            title: lotTitle,
            lotNumber,
            lotNumberExtension,
            photos,
            artmyns,
            isPassed: isLotPassed,
            isSold: isLotSold,
            closed: isLotClosed,
            inProgress: isLotInProgress,
            description: lotDescription,
            dimensions: lotDimensions,
            medium: lotMedium,
            circa: lotCirca,
            condition: conditionReport,
            provenance: lotProvenance,
            literature: lotLiterature,
            exhibited: lotExhibited,
            notes,
            saveCount: lotWatchedCount,
            postedDate,
            artistRef,
            reserveMet: isReserveMet,
            hasReserve,
            noFollow,
            noIndex,
          },
          _links: {
            catalog,
          },
          userItemProperties: {
            hasBid,
            bidStatus,
            isConciergeBid,
            watched: isLotWatched,
            maxBidAmount: highestBid,
            highBidder: isHighBidder,
            winningBidder: isWinningBidder,
            hasKeywordAlert: isLotNotified,
          },
        } = action.payload;

        const lotPhotos = formatLotPhotosWithArtmyn(photos, artmyns);

        return {
          ...state,
          catalogRef: catalog.href.split('/').reverse()[0],
          lotData: {
            lotTitle,
            lotId,
            lotNumber: `${lotNumber}${lotNumberExtension}`,
            isLotPassed,
            isLotSold,
            isLotClosed,
            lotRef,
            currentBid,
            soldAmount,
            currency,
            currencySymbol: currencySymbol !== currency ? currencySymbol : '',
            bidCount,
            estimateHigh,
            estimateLow,
            isLotInProgress,
            isLotWatched,
            isLotNotified,
            lotWatchedCount,
            photos: lotPhotos,
            lotDescription,
            lotDimensions,
            lotMedium,
            lotCirca,
            conditionReport,
            lotProvenance,
            lotLiterature,
            lotExhibited,
            notes,
            postedDate,
            artistRef,
            isReserveMet,
            hasReserve,
            artmyns,
            noFollow,
            noIndex,
          },
          artistInfo: {
            artistRef,
            fullName: artistName || artistFullName,
            firstName: artistFirstName,
            lastName: artistLastName,
          },
          bidData: {
            hasBid,
            highestBid,
            isHighBidder,
            bidStatus,
            isWinningBidder,
            isConciergeBid: isConciergeBid || false,
          },
        };
      }

      case FETCH_INITIAL_LOT_DATA_SUCCESS: {
        const {
          itemView: {
            lotId,
            artistName,
            artistFullName,
            artistFirstName,
            artistLastName,
            ref: lotRef,
            bidCount,
            estimateHigh,
            estimateLow,
            price: currentBid,
            currency,
            currencySymbol,
            priceResult: soldAmount,
            title: lotTitle,
            lotNumber,
            lotNumberExtension,
            photos,
            artmyns,
            isPassed: isLotPassed,
            isSold: isLotSold,
            closed: isLotClosed,
            inProgress: isLotInProgress,
            description: lotDescription,
            dimensions: lotDimensions,
            medium: lotMedium,
            circa: lotCirca,
            condition: conditionReport,
            provenance: lotProvenance,
            literature: lotLiterature,
            exhibited: lotExhibited,
            notes,
            saveCount: lotWatchedCount,
            postedDate,
            artistRef,
            reserveMet: isReserveMet,
            hasReserve,
            noFollow,
            noIndex,
          },
          _links: {
            catalog,
          },
          userItemProperties: {
            hasBid,
            bidStatus,
            isConciergeBid,
            watched: isLotWatched,
            maxBidAmount: highestBid,
            highBidder: isHighBidder,
            winningBidder: isWinningBidder,
            hasKeywordAlert: isLotNotified,
          },
          categories,
        } = action.payload;

        const {
          sellerModel: {
            sellerName,
            ref: sellerRef,
            _links: {
              sellerHomepageLogo: {
                href: sellerLogo,
              } = {},
            },
          },
          location: {
            addressStreet1,
            addressStreet2,
            addressCity,
            stateCode,
            province,
            postalCode,
            countryCode,
            location: address,
          } = {},
          catalogTitle,
          catalogId,
          ref: catalogRef,
          eventLocalDateIso8601,
          eventDate,
          timeZone,
          isLive,
          isTimed,
          inProgress: isAuctionInProgress,
          isUpcoming,
          isViewOnly,
          groupModel,
          dynamicPricingEnabled,
        } = action.payload.lotCatalog;

        const { displayParityBanner } = groupModel || {};
        const [date, timeZoneRegion] = extractAuctionDateAndTimeZoneRegion(eventLocalDateIso8601, timeZone);

        const lotPhotos = formatLotPhotosWithArtmyn(photos, artmyns);

        let paymentAndTermsDetail = {
          acceptedCreditCards: [],
          acceptedPaymentForms: '',
          shippingDetailsHTML: '',
          bidIncrements: [],
          buyersPremiums: [],
          conditionsOfSale: '',
          auctionTerms: [],
          paymentMethodsText: '',
        };

        const {
          houseExtensionTime,
          bidderFee,
          maxBuyersPremium: { buyersPremiumAmount: payableBP } = {},
          auctionBuyersPremiums: buyersPremiums,
        } = action.payload.auctionDisplay;

        if (action.payload.lotCatalog.groupModel) {
          const {
            paymentMethodsText: acceptedPaymentForms,
            shippingText: shippingDetailsHTML,
            acceptedCreditCards,
            bidIncrements,
            conditionsOfSale,
            auctionTerms,
          } = action.payload.lotCatalog.groupModel;

          paymentAndTermsDetail = {
            acceptedCreditCards,
            acceptedPaymentForms,
            shippingDetailsHTML,
            bidIncrements,
            buyersPremiums,
            conditionsOfSale,
            auctionTerms,
            isDataAvailable: true,
          };
        }

        const [supercategory, category, subcategory] = categories;

        return {
          ...state,
          catalogRef: catalog.href.split('/').reverse()[0],
          lotData: {
            lotTitle,
            lotId,
            lotNumber: `${lotNumber}${lotNumberExtension}`,
            isLotPassed,
            isLotSold,
            isLotClosed,
            lotRef,
            currentBid,
            soldAmount,
            currency,
            currencySymbol: currencySymbol !== currency ? currencySymbol : '',
            bidCount,
            estimateHigh,
            estimateLow,
            isLotInProgress,
            isLotWatched,
            isLotNotified,
            lotWatchedCount,
            photos: lotPhotos,
            lotDescription,
            lotDimensions,
            lotMedium,
            lotCirca,
            conditionReport,
            lotProvenance,
            lotLiterature,
            lotExhibited,
            notes,
            postedDate,
            artistRef,
            isReserveMet,
            hasReserve,
            artmyns,
            noFollow,
            noIndex,
          },
          artistInfo: {
            artistRef,
            fullName: artistName || artistFullName,
            firstName: artistFirstName,
            lastName: artistLastName,
          },
          bidData: {
            hasBid,
            highestBid,
            isHighBidder,
            bidStatus,
            isWinningBidder,
            isConciergeBid,
          },
          breadCrumbs: action.payload.categories.map(categoryBreadcrumb => ({
            name: get(categoryBreadcrumb, 'categoryName', ''),
            ref: get(categoryBreadcrumb, 'categoryRef', '').toUpperCase(),
            URL: get(categoryBreadcrumb, 'linkCategoryURL'),
            level: get(categoryBreadcrumb, 'categoryLevel'),
          })),
          catalogData: {
            id: catalogId,
            title: catalogTitle,
            ref: catalogRef,
            date,
            eventDate,
            timeZone,
            timeZoneRegion,
            isLive,
            isTimed,
            isUpcoming,
            isViewOnly,
            isAuctionInProgress,
            displayParityBanner,
            supercategory,
            category,
            subcategory,
            bannedBySMSProvider: categories && categories.some(({ bannedBySMSProvider }) => bannedBySMSProvider),
            dynamicPricingEnabled,
          },
          auctionHouseData: {
            name: sellerName,
            ref: sellerRef,
            addressStreet: `${addressStreet1} ${addressStreet2}`.trim(),
            addressCity,
            addressRegion: stateCode || province,
            postalCode,
            countryCode,
            address,
            sellerLogo,
          },
          catalogTermsData: {
            bidderFee,
            houseExtensionTime,
            payableBP,
          },
          paymentAndTermsDetail,
        };
      }

      case FETCH_PDP_BIDDER_STATUS_SUCCESS: {
        const { approvalStat: approvalStatus } = action.payload;
        return {
          ...state,
          bidderStatus: {
            approvalStatus,
          },
        };
      }

      case PDP_SET_ALGOLIA_PARAMS: {
        const { oasNodeSid, algoliaAPI, algoliaID } = action.payload;
        return {
          ...state,
          algoliaParams: {
            oasNodeSid,
            algoliaAPI,
            algoliaID,
          },
        };
      }

      case FETCH_PDP_LOT_LIVE_INFO_SUCCESS: {
        const {
          secondsToClose,
          endTime,
          bidCount,
          isClosed,
          isOutBid,
          isExtended,
          currentBidAmount,
        } = action.payload;
        const { hasBid, bidStatus: prevBidStatus } = state.bidData;
        const bidStatus = isOutBid && hasBid ? 'outbid' : prevBidStatus;
        return {
          ...state,
          lotLiveInfo: {
            secondsToClose,
            endTime,
            isClosed,
            isExtended,
          },
          lotData: {
            ...state.lotData,
            bidCount,
            currentBid: currentBidAmount,
          },
          bidData: {
            ...state.bidData,
            bidStatus,
          },
        };
      }

      case POST_PDP_ADD_TO_WATCH_LIST_SUCCESS: {
        const lotWatchedCount = state.lotData.lotWatchedCount + 1;
        return {
          ...state,
          lotData: {
            ...state.lotData,
            lotWatchedCount,
            isLotWatched: true,
          },
        };
      }

      case POST_PDP_REMOVE_FROM_WATCH_LIST_SUCCESS: {
        const lotWatchedCount = state.lotData.lotWatchedCount - 1;
        return {
          ...state,
          lotData: {
            ...state.lotData,
            lotWatchedCount,
            isLotWatched: false,
          },
        };
      }

      case FETCH_PDP_WATCHER_COUNT_SUCCESS:
        return {
          ...state,
          catalogData: {
            ...state.catalogData,
            liveWatchersCount: action.payload[state.catalogRef],
          },
        };

      case FETCH_PDP_USER_CONTACT_INFO_SUCCESS: {
        const { user, countries, states } = action.payload;

        const {
          userAddress: {
            addressStreet1,
            addressStreet2,
            addressCity,
            province,
            postalCode,
            stateCode,
            showStates,
            countryCode,
            addressPhone,
          } = {},
          firstName,
          lastName,
          email,
        } = user;

        const userAddressDetail = {
          addressStreet1,
          addressStreet2,
          shouldAddAddressStreet2: !!addressStreet2,
          addressCity,
          province,
          postalCode,
          stateCode,
          showStates,
          states,
          countryCode,
          addressPhone,
        };

        return {
          ...state,
          contactInfo: {
            ...state.contactInfo,
            [PLACE_BID_NESTED_FORMS.SHIPPING_ADDRESS]: { firstName, lastName, ...userAddressDetail },
            [PLACE_BID_NESTED_FORMS.BILLING_ADDRESS]: userAddressDetail,
            [PLACE_BID_NESTED_FORMS.SMS_OPT_IN]: {
              ...state.contactInfo.smsPhone,
              phone: addressPhone,
              countryCode,
            },
            countries,
            email,
          },
        };
      }

      case FETCH_PDP_STATES_SUCCESS: {
        const { statesData: { states, fieldLabel }, formType } = action.payload;

        return {
          ...state,
          contactInfo: {
            ...state.contactInfo,
            [formType]: {
              ...state.contactInfo[formType],
              stateCode: '',
              province: '',
              showStates: states && !!states.length,
              states,
              territoryLabel: fieldLabel,
            },
          },
        };
      }

      case PDP_UPDATE_PLACE_BID_FORM_DATA: {
        const { key, updatedValue, nestedForm } = action.payload;
        return {
          ...state,
          contactInfo: {
            ...state.contactInfo,
            [nestedForm]: {
              ...state.contactInfo[nestedForm],
              [key]: updatedValue,
            },
          },
        };
      }

      case PDP_UPDATE_PLACE_BID_FORM_ERRORS: {
        const { errors, errorType, overwrite } = action.payload;
        return {
          ...state,
          contactInfo: {
            ...state.contactInfo,
            formErrors: {
              ...state.contactInfo.formErrors,
              [errorType]: overwrite ? errors : {
                ...state.contactInfo.formErrors[errorType],
                ...errors,
              },
            },
          },
        };
      }

      case RESET_PDP_PLACE_BID_FORM_ERRORS: {
        const errorType = action.payload;
        return {
          ...state,
          contactInfo: {
            ...state.contactInfo,
            formErrors: {
              [errorType]: undefined,
            },
          },
        };
      }

      case FETCH_PDP_REQUEST_FOR_APPROVAL_INIT_SUCCESS: {
        const { pageData: { showRFA } } = action.payload;
        return {
          ...state,
          approvalInfo: {
            ...state.approvalInfo,
            showRFA,
          },
          isLoading: false,
        };
      }

      case FETCH_PDP_REQUEST_FOR_APPROVAL_SUCCESS: {
        const {
          pageData: {
            showCreditCardSection,
            userCreditCards,
            availableCreditCardTypes,
            catalog: {
              hasPaymentProcessing,
            },
          },
        } = action.payload;
        return {
          ...state,
          ccInfo: {
            ...state.ccInfo,
            showCreditCardSection,
            hasPaymentProcessing,
            userCreditCards,
            availableCreditCardTypes,
          },
        };
      }

      case FETCH_PDP_BILLING_CARDS_SUCCESS: {
        const cards = action.payload;
        return {
          ...state,
          ccInfo: {
            ...state.ccInfo,
            userCreditCards: cards,
          },
        };
      }

      case PDP_SET_ARE_BILLING_SHIPPING_ADDRESS_SAME: {
        return {
          ...state,
          areBillingShippingAddressSame: action.payload,
        };
      }

      case POST_PDP_UPDATE_BID_TOTAL_SUCCESS: {
        const {
          buyersPremiumAmount,
          totalAmount,
          orderBidResponse: { errorMessage, KYCBidLimited, kycStatus, kycBidLimit, bidLimit, currency
          },
          dynamicPricing,
        } = action.payload;
        return {
          ...state,
          bidInfo: {
            ...state.bidInfo,
            bpAmount: buyersPremiumAmount,
            bidTotalAmount: totalAmount,
            errorMsg: errorMessage,
            KYCBidLimited,
            kycStatus,
            kycBidLimit,
            currency,
            bidLimit,
            dynamicPricing,
          },
        };
      }

      case SET_PDP_SELECTED_BID_INCREMENT: {
        return {
          ...state,
          selectedBidIncrement: action.payload,
          selectedBidAmount: action.payload,
        };
      }

      case PDP_SET_SELECTED_BID_AMOUNT: {
        return {
          ...state,
          selectedBidAmount: action.payload,
        };
      }

      case FETCH_PDP_WATCHER_COUNT_ERROR:
        return {
          ...state,
          errors: {
            ...state.errors,
            watcherCountCallFailed: true,
          },
        };

      case POST_PDP_FOLLOW_UN_FOLLOW_SELLER_SUCCESS: {
        const isFollowing = action.payload;
        const followCount = isFollowing
          ? state.sellerFollowCount + 1
          : state.sellerFollowCount - 1;
        return {
          ...state,
          isFollowingSeller: isFollowing,
          sellerFollowCount: followCount,
        };
      }

      case POST_PDP_FOLLOW_UN_FOLLOW_SELLER_ERROR:
        return {
          ...state,
          errors: {
            ...state.errors,
            followUnFollowSellerCallFailed: true,
          },
        };

      case PDP_SET_BID_CONCIERGE_STATUS:
        return {
          ...state,
          conciergeStatus: action.payload,
        };

      case PDP_UPDATE_SELECTED_CARD_DETAILS: {
        return {
          ...state,
          ccInfo: {
            ...state.ccInfo,
            selectedCardDetails: action.payload,
          },
        };
      }

      case PDP_UPDATE_NEW_CARD_DETAILS: {
        return {
          ...state,
          ccInfo: {
            ...state.ccInfo,
            newlyAddedCardDetails: { ...state.ccInfo.newlyAddedCardDetails, ...action.payload },
          },
          contactInfo: {
            ...state.contactInfo,
            formErrors: {
              ...state.contactInfo.formErrors,
            },
          },
        };
      }

      case PDP_USE_NEW_CREDIT_CARD: {
        return {
          ...state,
          ccInfo: {
            ...state.ccInfo,
            isUseNewCard: action.payload,
          },
        };
      }

      case POST_PDP_NOTIFY_ME_SUCCESS:
        return {
          ...state,
          lotData: {
            ...state.lotData,
            isLotNotified: action.payload,
          },
        };

      case POST_PDP_NOTIFY_ME_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            notifyMecallFailed: true,
          },
        };
      }
      case FETCH_PDP_IS_SELLER_FOLLOWED_SUCCESS:
        return {
          ...state,
          isFollowingSeller: action.payload,
        };

      case FETCH_PDP_SUBSCRIPTION_INFO_SUCCESS: {
        const { displayPrices } = action.payload;
        return {
          ...state,
          subscriptionInfo: {
            displayPrices,
          },
        };
      }

      case FETCH_PDP_SUBSCRIPTION_INFO_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            subscriptionInfoCallFailed: true,
          },
        };
      }

      case FETCH_PDP_IS_SELLER_FOLLOWED_ERROR:
        return {
          ...state,
          isFollowingSeller: false,
          errors: {
            ...state.errors,
            isFollowedSellerCallFailed: true,
          },
        };

      case PDP_SET_CURRENCY_COVERSION_RATE: {
        const { conversionRate, currencyCode } = action.payload;
        return {
          ...state,
          lotData: {
            ...state.lotData,
            localCurrencyConversionRate: conversionRate,
            localCurrencyCode: currencyCode,
          },
        };
      }

      case FETCH_PDP_SELLER_FOLLOW_COUNT_SUCCESS:
        return {
          ...state,
          sellerFollowCount: action.payload,
        };

      case FETCH_PDP_SELLER_FOLLOW_COUNT_ERROR:
        return {
          ...state,
          errors: {
            ...state.errors,
            sellerCountCallFailed: true,
          },
        };

      case POST_PDP_ADD_TO_WATCH_LIST_ERROR:
        return {
          ...state,
          errors: {
            ...state.errors,
            addToWatchListCallFailed: true,
          },
        };

      case POST_PDP_REMOVE_FROM_WATCH_LIST_ERROR:
        return {
          ...state,
          errors: {
            ...state.errors,
            removeToWatchListCallFailed: true,
          },
        };

      case FETCH_PDP_SELLER_INFO_SUCCESS: {
        const {
          pageData: {
            messageSubjects,
          },
        } = action.payload.data;

        return {
          ...state,
          sellerInfo: {
            messageSubjects,
          },
        };
      }

      case SET_PDP_ASYNC_STATUS: {
        const { status, type } = action.payload;
        return {
          ...state,
          asyncStatus: {
            ...state.asyncStatus,
            [type]: status,
          },
        };
      }

      case FETCH_PDP_ARTIST_INFO_SUCCESS: {
        const { displayName, foreName, firstName, lastName } = action.payload;
        return {
          ...state,
          artistInfo: {
            ...state.artistInfo,
            fullName: displayName,
            firstName,
            lastName,
            foreName,
          },
        };
      }

      case PDP_FETCH_VIP_STATUS_SUCCESS: {
        const { vip } = action.payload;
        return {
          ...state,
          isUserVip: vip,
        };
      }

      case PDP_FETCH_VIP_STATUS_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            vipBadgeCallFailed: true,
          },
        };
      }

      case FETCH_DYNAMIC_TERMS_SUCCESS: {
        return {
          ...state,
          dynamicTerms: action.payload,
        };
      }

      case FETCH_PDP_ARTIST_INFO_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            getArtistInfoCallFailed: true,
          },
        };
      }

      case PDP_SET_BID_SUBMIT_RESPONSE: {
        return {
          ...state,
          bidSubmitResponse: action.payload,
        };
      }

      case PDP_SET_BID_SUBMIT_ERROR_MESSAGE: {
        return {
          ...state,
          bidInfo: {
            ...state.bidInfo,
            errorMsg: action.payload,
          },
        };
      }

      case PDP_SET_BID_SUBMIT_ERRORS_DATA: {
        const { KYCBidLimited, bidLimit, kycBidLimit, kycStatus } = action.payload;
        return {
          ...state,
          bidInfo: {
            ...state.bidInfo,
            KYCBidLimited,
            kycStatus,
            kycBidLimit,
            bidLimit,
          },
        };
      }

      case FETCH_PDP_BILLING_CARDS_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            requestForMemberBillingCallFailed: true,
          },
        };
      }

      case POST_PDP_UPDATE_BID_TOTAL_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            updateBidTotalCallFailed: true,
          },
          isLoading: false,
        };
      }

      case FETCH_PDP_LOT_ERROR:
        return {
          ...state,
          errors: {
            ...state.errors,
            lotCallFailed: true,
          },
        };

      case FETCH_INITIAL_LOT_DATA_ERROR:
        return {
          ...state,
          errors: {
            ...state.errors,
            initialLotCallFailed: true,
          },
        };

      case FETCH_PDP_BIDDER_STATUS_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            bidderStatusCallFailed: true,
          },
        };
      }

      case FETCH_PDP_LOT_LIVE_INFO_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            timedLotInfoCallFailed: true,
          },
        };
      }

      case FETCH_PDP_SELLER_INFO_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            sellerInfoCallFailed: true,
          },
        };
      }

      case FETCH_PDP_USER_CONTACT_INFO_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            contactInfoCallFailed: true,
          },
        };
      }

      case FETCH_PDP_STATES_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            fetchCountryStatesCallFailed: true,
          },
        };
      }

      case FETCH_PDP_REQUEST_FOR_APPROVAL_INIT_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            requestForApprovalInitCallFailed: true,
          },
          isLoading: false,
        };
      }

      case FETCH_PDP_REQUEST_FOR_APPROVAL_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            requestForApprovalCallFailed: true,
          },
        };
      }

      case SET_PDP_IS_LOADING: {
        return {
          ...state,
          isLoading: action.payload,
        };
      }

      case PDP_SET_IS_PROD_ENV: {
        return {
          ...state,
          isProd: action.payload,
        };
      }

      case SET_PDP_CONCIERGE_MODAL_PROPS:
        return {
          ...state,
          conciergeModalProps: action.payload,
        };

      case RESET_PDP_ERRORS: {
        return {
          ...state,
          errors: Object.fromEntries(Object.keys(state.errors).map(key => [key, false])),
        };
      }

      case SET_PDP_STATUS_CODE: {
        return {
          ...state,
          statusCode: action.payload,
          errors: {
            ...state.errors,
            notFoundError: true,
          },
        };
      }

      case SET_INV_PROMISE_MODAL_OPEN: {
        return {
          ...state,
          isPromiseModalOpen: action.payload,
        };
      }

      case SET_DYNAMIC_PRICING_MODAL_OPEN: {
        return {
          ...state,
          isDynamicPricingModalOpen: action.payload,
        };
      }

      default:
        return state;
    }
  } catch (error) {
    logger.error('Error in Reducer', error);
    return state;
  }
}

export default pdp;
