export const NESTED_MODALS = {
  TERMS_AND_CONDITION: 'T&C',
  SHIPPING: 'shipping',
  DYNAMIC_PRICING: 'dynamic-pricing',
  NONE: 'none',
};

export const KYC_STATUSES = {
  SUCCESS: 's',
};

export const FORM_ERRORS = {
  countryCode: 'Please select a valid country.',
  phone: 'Please enter a valid phone number. In the US or Canada, phone number needs 10 digits.',
  firstName: 'Please enter a first name.',
  lastName: 'Please enter a last name.',
  addressStreet1: 'Please enter a valid address.',
  addressStreet2: 'Please enter a valid address.',
  addressCity: 'Please enter a valid city.',
  stateCode: 'Please select a valid',
  province: 'Please enter a valid province.',
  postalCode: 'Please enter a valid postal code.',
  addressPhone: 'Please enter a valid phone number. In the US or Canada, phone number needs 10 digits.',
};

export const BID_SUBMIT_RESPONSE = {
  SUCCESS: 'success',
  PENDING: 'pending',
  NONE: 'none',
};

export const BID_STATUS = {
  OUTBID: 'outbid',
  HIGHEST: 'highest',
  PENDING: 'pending',
};

export const HEAP_EVENTS = {
  BID_SUBMIT_SUCCESS: 'Bid',
  RFA_ERROR: 'RFA_ERROR',
};

export const GA_EVENTS = {
  ADD_TO_CART: 'addToCart',
};

export const GA_CATEGORIES = {
  BIDDER_CONSOLE: 'Bidder Console',
};

export const ORDER_BID_ERRORS_KEYWORDS = {
  BID_LIMIT_ERROR: 'You have exceeded your bid limit',
};

export const COUNTRIES_THAT_REQ_STATE_PROVINCE = ['UK', 'AU', 'IE', 'CA', 'US'];

export const VERIFY_ACCOUNT_LINK = '/my-account/verify';
