import { GetSecretValueCommand, SecretsManagerClient } from '@aws-sdk/client-secrets-manager';
import algoliasearch from 'algoliasearch';

import { LOGIN_MODAL_ID } from 'src/components/common/constants';
import logger from 'routes/middleware/logging/logger';
import { setModalStatus, setPostLoginRedirectUrl } from 'src/actions/inv-common.actions';
import { ALGOLIA_SECRETS, NODE_ENVS } from '../../../routes/constants';
import countryCurrencies from '../algolia-helper/currencies';
import { convertCurrency } from '../algolia-helper';
import { MODAL_NAMES } from '../constants';

export const isClientProdEnv = () => {
  const urlEnv = window.location.hostname.split('.')[0];
  return (process.env.NODE_ENV === NODE_ENVS.PROD && urlEnv === 'www');
};

/**
 * utility to check environment
 * @param {string} hostname to check environment type
 */
export const getIsProdEnv = (hostname) => {
  // if hostname is undefined return check based on env variable
  if (!hostname) return process.env.NODE_ENV === NODE_ENVS.PROD;
  const urlEnv = hostname.split('.')[0];
  return process.env.NODE_ENV === NODE_ENVS.PROD && urlEnv === 'www';
};

/**
 * Returns the base domain for image URLs based on getImageServerDomain's result.
 *
 * @param {string} hostname
 * @returns {string} The base domain for image URLs.
 */
export const getImageServerDomain = (hostname) => {
  const domainPrefix = getIsProdEnv(hostname) ? 'image' : 'stageimg';
  return `https://${domainPrefix}.invaluable.com`;
};

/**
 * Returns the base domain for image URLs based on the given environment.
 *
 * This function utilizes the {isProduction} flag from Redux state, as it is set on the server-side,
 * ensuring the correct environment is determined.
 *
 * @param {boolean} isProduction - Indicates if the environment is production.
 * @returns {string} The base domain for image URLs.
 */
export const getImageBaseDomain = (isProduction) => {
  const domainPrefix = isProduction ? 'image' : 'stageimg';
  return `https://${domainPrefix}.invaluable.com`;
};

/**
 * Triggers a click event on the login link element, opening up the login modal.
 */
export const openLoginModal = (dispatch) => {
  const loginModalLink = document.getElementById(LOGIN_MODAL_ID);
  if (loginModalLink) {
    loginModalLink.click();
  } else if (dispatch) {
    dispatch(setModalStatus({ name: MODAL_NAMES.login, isOpen: true }));
  }
};

/**
 * A utility to get the currency conversion rates given the catalog currency, user's country code and the hostname.
 * @param cfIpCountry Present in req.headers as 'cf-ipcountry'. It will only be available in production and stage
 * with hostfile configured, not locally. In order to access this header point your stage to cloudflare with
 * 104.18.25.55    stage.invaluable.com
 * in your hostfile. The countrycode will be represented in ISO 3166-1 Alpha 2 format.
 * To test locally, use ModHeader or a similar extension.
 * @param currencyCode Currency country code set on a catalog
 * @param hostname
 * @return {Promise<[] | undefined>} List of objects with currency and the conversion rate e.g.,
 * [ { currencyCode: 'USD', conversionRate: 0.007549 } ]
 */
export const fetchCurrencyConvertedRates = async (cfIpCountry, currencyCode, hostname) => {
  const localCurrencyCode = countryCurrencies[cfIpCountry];

  if (currencyCode && localCurrencyCode && currencyCode !== localCurrencyCode) {
    try {
      const currencyConversions = await convertCurrency(hostname, [currencyCode], localCurrencyCode);
      if (currencyConversions.length) {
        currencyConversions[0].currencyCode = localCurrencyCode;
      }
      return currencyConversions;
    } catch (error) {
      console.error('Error converting currency', error);
    }
  }
};

/**
 * A utility that returns an amount formatter which formats the given amount based on the currency.
 * By default it returns a USD amount formatter
 * @param string currencyCode - Currency code
 * @param string currencyDisplay - It is used for currency formatting, its values could be symbol,code etc.
 * @return {Intl.NumberFormat}
 */
export const getAmountFormatter = (currencyCode = 'USD', currencyDisplay = 'code') => new Intl.NumberFormat('en-US', {
  style: 'currency', currency: currencyCode, currencyDisplay, maximumFractionDigits: 0, minimumFractionDigits: 0
});

/**
 * Checks if the current environment is the production environment.
 *
 * @returns {boolean} Returns true if the current environment is production, false otherwise.
 */
export const getIsProductionEnv = () => process.env.NODE_ENV === NODE_ENVS.PROD;

/**
 * A utility that fetches algolia API and algolia ID from AWS
 * @returns returns algoliaAPI and algoliaID
 */
export const fetchAlgoliaKeysFromAWS = async () => {
  const { AWS_REGION, SECRET_ID_PROD, SECRET_ID_STAGE } = ALGOLIA_SECRETS;

  const client = new SecretsManagerClient({ region: AWS_REGION });
  const command = new GetSecretValueCommand({ SecretId: getIsProductionEnv() ? SECRET_ID_PROD : SECRET_ID_STAGE });

  let { algoliaAPI, algoliaID } = {};

  try {
    const data = await client.send(command);
    const parsedSecret = JSON.parse(data.SecretString);
    algoliaAPI = parsedSecret['algolia.api.id'];
    algoliaID = parsedSecret['algolia.api.key'];
  } catch (error) {
    // eslint-disable-next-line no-console
    throw new Error('Error fetching Algolia secrets from AWS Secrets Manager', { cause: error });
  }

  return { algoliaAPI, algoliaID };
};

/**
 * Fetches Algolia API keys from AWS, creates AWS client and returns the client
 * alongside key.
 *
 * @returns { Object } contains the client and the keys
 */
export const createAlgoliaClient = async () => {
  const { algoliaAPI, algoliaID } = await fetchAlgoliaKeysFromAWS();
  const algoliaClient = algoliasearch(algoliaID, algoliaAPI);

  return { algoliaClient, algoliaAPI, algoliaID };
};

/**
    Determines if the given URL is the same as the previous URL in the browser history.
    @param {string} newUrl - The URL to compare with the previous URL.
    @param {object} history - The history object containing information about the previous URL.
    @returns {boolean} - Returns true if the URL is the same as the previous URL; otherwise, returns false.
*/
export const getIsUrlSameAsPrevious = (newUrl, history) => {
  const { pathname: prevPath, search: prevSearch } = history.location;
  const isUrlSameAsPrevious = newUrl === `${prevPath}${prevSearch}`;

  return isUrlSameAsPrevious;
};

/**
 * A uitility that returns text for singular and plural cases depending on the count provided.
 * @param {number} count - Count to determine if it is plural or singular case.
 * @param {string} text - Default singular text case provided.
 * @return {string} - Returns plural text if count greater than 1 else returns the default singular text provided.
 */
export const pluralizeText = (count, text) => (count > 1 ? `${text}s` : text);

/**
 * utility that tracks given event on heap
 * @param {string} event - text that need to track by heap
 * @param {number} retryAttempt - Number of attempts the function did.
 */
export const heapTrack = (event, retryCount = 0) => {
  const retryAttempt = retryCount + 1;

  if (window?.heap?.loaded) {
    try {
      // eslint-disable-next-line no-undef
      heap.track(event);
    } catch (error) {
      logger.error('Heap tracking error', error);
    }
  } else {
    if (retryAttempt > 10) {
      return;
    }
    setTimeout(() => {
      heapTrack(event, retryAttempt);
    }, 1000);
  }
};

/**
 * utility that tracks given event on heap
 * @param {string} event - text that need to track by heap
 * @param {object} dataObject - Object containing additional params to be tracked
 * @param {number} retryAttempt - Number of attempts the function did.
 */
export const heapTrackWithParams = ({ eventMessage, dataObject = {}, retryCount = 0 }) => {
  const retryAttempt = retryCount + 1;

  if (window?.heap?.loaded) {
    try {
      // eslint-disable-next-line no-undef
      heap.track(eventMessage, dataObject);
    } catch (error) {
      logger.error('Heap params tracking error', error);
    }
  } else {
    if (retryAttempt > 10) {
      return;
    }
    setTimeout(() => {
      heapTrackWithParams({ eventMessage, dataObject, retryAttempt });
    }, 1000);
  }
};

export const getIsBot = (req) => {
  const botScore = req.headers['x-bot-score'] || 2;
  const isBot = botScore < 2;
  return isBot;
};

export const fullPageLoadingSpinnerToggle = (isLoading) => {
  if (isLoading) {
    document.querySelector('.fullPageSpinner').style.display = 'block';
  } else {
    document.querySelector('.fullPageSpinner').style.display = 'none';
  }
};

export const handleClickRFA = ({ isLoggedIn, linkRFAURL }) => (dispatch) => {
  if (isLoggedIn) {
    window.sessionStorage.setItem('rfaRedirectAddress', window.location);
    window.location = linkRFAURL;
  } else {
    window.sessionStorage.setItem('rfaRedirectAddress', window.location);
    dispatch(setModalStatus({
      name: MODAL_NAMES.login,
      isOpen: true,
    }));
    dispatch(setPostLoginRedirectUrl(linkRFAURL));
  }
};
