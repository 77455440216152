import { RESTFulService } from '../rest-client/restful-service';
import { routes } from '../../../config';

import { getArtistInfoURL, getfollowUnFollowSellerURL, getSubscriptionInfoURL, isFollowedSellerURL } from './helpers';

class PDPService extends RESTFulService {
  getUserContactInfo = async () => {
    const contactInfo = await this.get(routes.api.PDP_GET_CONTACT_INFO);
    return contactInfo;
  };

  getStatesForTheGivenCountry = async (countryCode) => {
    const statesResponse = await this.get(`${routes.api.PDP_GET_STATES}/?country=${countryCode}`);
    return statesResponse;
  };

  fetchLiveConsoleUrl = async (apiUrl) => {
    const { data: { consoleUrl } } = await this.get(apiUrl);
    return consoleUrl;
  };

  getIsSellerFollowed = async (houseRef, authToken) => {
    const url = isFollowedSellerURL(houseRef);
    const params = {
      options: { headers: { 'x-auth-token': authToken } },
    };
    return this.get(url, params);
  };

  postFollowUnFollowSeller = async (houseRef, follow, authToken) => {
    const url = getfollowUnFollowSellerURL(houseRef, follow);
    const params = {
      options: { headers: { 'x-auth-token': authToken } },
    };
    return this.post(url, params);
  };

  getFollowSellerCount = async (houseRef) => {
    const params = {
      queryParams: { houseRef },
    };

    return this.get(routes.api.PDP_FOLLOW_SELLER_COUNT, params);
  };

  getSubscriptionInfo = async (hostname, headers, catalogId) => {
    const url = getSubscriptionInfoURL(hostname, catalogId);
    const params = {
      options: { headers },
    };
    return this.get(url, params);
  };

  getPDPLot = async (lotId, memberId, hostname, xAuthToken) => {
    const params = { options: { headers: { 'member-id': memberId, 'x-auth-token': xAuthToken } } };
    const url = `https://${hostname}${routes.api.PDP_LOT}/${lotId}`;
    return this.get(url, params);
  };

  getInitialLotData = async (lotId, hostname, xAuthToken) => {
    const params = { options: { headers: { 'x-auth-token': xAuthToken || null } } };
    const url = `https://${hostname}${routes.api.PDP_LOT_V2}${lotId}/pdp`;
    return this.get(url, params);
  };

  getBidderStatus = async (hostname, catalogRef, memberId) => {
    const params = { options: { headers: { 'member-id': memberId } } };
    const url = `https://${hostname}${routes.api.PDP_BIDDER_STATUS}/${catalogRef}`;
    return this.get(url, params);
  };

  getLotLiveInfo = async (lotRef, memberId) => (
    this.get(`${routes.api.PDP_LOT_LIVE_INFO}/${lotRef}/${memberId}`)
  );

  postAddToWatchlist = async lotID => this.post(`${routes.api.PDP_WATCH_LOT}/${lotID}`);

  postRemoveFromWatchlist = async lotID => (
    this.post(`${routes.api.PDP_UNWATCH_LOT}/${lotID}`)
  );

  getSellerInfo = async (lotId, cookie, hostname) => {
    const url = `https://${hostname}${routes.api.PDP_GET_SELLER_INFO}/${lotId}`;
    const params = { options: { headers: { cookie } } };
    return this.get(url, params);
  };

  postSellerMessage = async (data, memberId) => {
    const params = { options: { headers: { 'member-id': memberId } }, data };
    return this.post(routes.api.PDP_SELLER_MESSAGE, params);
  };

  getWatcherCount = async catalogRef => (
    this.get(`${routes.api.PDP_WATCHER_COUNT}=${catalogRef}`)
  );

  getRequestForApprovalInit = async (catalogRef, lotRef) => {
    const approvalResponse = await this.get(`${routes.api.PDP_REQUEST_FOR_APPROVAL_INIT}/${catalogRef}/${lotRef}`);
    return approvalResponse;
  };

  getRequestForApproval = async (catalogRef, lotRef, bidAmount) => {
    const approvalResponse = await this.get(`${routes.api.PDP_REQUEST_FOR_APPROVAL}/${catalogRef}/${lotRef}/${bidAmount}`);
    return approvalResponse;
  };

  getMemberBillingCards = async () => {
    const billingResponse = await this.get(routes.api.PDP_MEMBER_BILLING_CARDS);
    return billingResponse;
  };

  postUpdateBidTotal = async (lotRef, bidAmount, conciergeBid) => {
    const params = { data: { lotRef, bidAmount, v2: true, conciergeBid } };
    const bidTotalResponse = await this.post(routes.api.PDP_UPDATE_BID_TOTAL, params);
    return bidTotalResponse;
  };

  postNotifyMe = async (lotId, authToken) => {
    const params = {
      data: {
        withAll: '',
        withOne: '',
        withPhrase: '',
        notWith: '',
        lotId,
      },
      options: { headers: { 'x-auth-token': authToken } },
    };
    return this.post(routes.api.PDP_NOTIFY_ME, params);
  };

  getArtistInfo = async (hostname, artistRef) => {
    const url = getArtistInfoURL(hostname, artistRef);
    const artistInfoResponse = await this.get(url);
    return artistInfoResponse;
  };

  getBidInput = async (lotRef, bidAmount) => this.get(`${routes.api.PDP_ORDER_BID_INIT}/${lotRef}/${bidAmount}`);

  postOrderBid = async (lotRef, bidAmount, conciergeBid) => {
    const params = {
      data: {
        lotRef,
        bidAmount,
        conciergeBid,
        v2: true,
      },
    };
    const orderBidResponse = await this.post(`${routes.api.PDP_ORDER_BID}`, params);
    return orderBidResponse;
  };

  postLotSMSOptIn = async (lotRef, countryCode, phoneNo) => {
    const response = await this.post(
      `${routes.api.PDP_LOT_SMS_OPT_IN}/${lotRef}?countryCode=${countryCode}&phoneNbr=${phoneNo}`
    );
    return response.data.data;
  };

  postCatalogSMSOptIn = async (catalogRef, countryCode, phoneNo) => {
    const response = await this.post(
      `${routes.api.PDP_CATALOG_SMS_OPT_IN}/${catalogRef}?countryCode=${countryCode}&phoneNbr=${phoneNo}`
    );
    return response.data.data;
  };

  postSubmitRFA = async rfaData => this.post(`${routes.api.PDP_SUBMIT_RFA}`, { data: { ...rfaData, v2: true } });

  getVIPBadge = async (hostname, authToken) => {
    const params = {
      options: { headers: { 'x-auth-token': authToken } },
    };
    return this.get(`https://${hostname}${routes.api.PDP_GET_VIP_STATUS}`, params);
  };

  getDynamicTerms = async ({ catalogRef, bidIncrement }) => {
    const res = await this.get(`/api/auctions/catalog/${catalogRef}/dynamicpricing?firstAvailableBidIncrement=${bidIncrement}`);
    return res;
  };
}

export default new PDPService();
